// eslint-disable
import React, { Suspense } from 'react'
import withPreview, { Loading } from 'services/withPreview'

// Theme
import Theme from 'styles/Theme'

// CSS
import 'styles/all.scss'

const PreviewTemplate = ({ postPreview, postType }) => {
  let Template = null

  /*
    Make sure the custom post types name is equal to the template name.
  */
  if (postPreview && postPreview.template === 'index.php') {
    Template = React.lazy(() => import(`src/pages/index`))
  } else if (postPreview && postPreview.template) {
    Template = React.lazy(() =>
      import(`templates/${postPreview.template.replace('.php', '')}`)
    )
  } else if (postType) {
    Template = React.lazy(() => import(`templates/${postType}`))
  }

  if (!Template) {
    return <span>Geen voorbeeld sjabloon gevonden</span>
  }

  const data = {
    page: {
      path: postPreview.slug,
      content: postPreview.content.rendered,
      title: postPreview.title.rendered,
      categories: postPreview.categories,
      yoast_meta: null,
      acf: postPreview.acf,
      flexible: [],
    },
  }

  if (postPreview.acf && postPreview.acf.flexible) {
    data.page.acf.flexible_page = postPreview.acf.flexible.map((section) => {
      section.internal = {
        type: `WordPressAcf_${section.acf_fc_layout}`,
      }

      return section
    })
  }

  return (
    <Theme>
      <Suspense fallback={<Loading description="Voorbeeld aan het laden" />}>
        <Template postPreview={postPreview} data={data} />
      </Suspense>
    </Theme>
  )
}

export default withPreview(PreviewTemplate)
